import React from 'react'
import { connect } from 'react-redux'
import { links, API } from '../index'

import { NavLink, useLocation, Link } from 'react-router-dom'
import classnames from 'classnames'

function FloatBar(props) {
	const [entities, setEntities] = React.useState([])
	const [points, setPoints] = React.useState([])
	const [winner, setWinner] = React.useState([])
	React.useEffect(() => {
		API({
			...props.params,
			urlparams: { location: props.location.current.locationid },
			bodydata: {},
			isfile: false,
			callback: (res) => {
				if (res.data.status === 200) {
					setEntities(res.data.data)
				} else {
				}
			},
		})
	}, [props.location])

	React.useEffect(() => {
		API({
			...links.get_points,
			urlparams: { location: props.location.current.locationid },
			bodydata: {},
			isfile: false,
			callback: (res) => {
				if (res.data.status === 200) {
					setPoints(res.data.data)
					setWinner(res.data.data)
				}
			},
		})
	}, [props.location])

	React.useEffect(() => {}, [winner])
	return (
		<div class='mb-1'>
			<svg height='30px' width='100%'>
				<defs>
					<linearGradient id='grad1' x1='0%' y1='0%' x2='75%' y2='0%'>
						{/* <stop offset="50%" style={{stopColor:"rgb(0,110,200)", stopOpacity:0.8}} /> */}
						<stop
							offset='50%'
							style={{ stopColor: 'rgb(5,80,200)', stopOpacity: 0.9 }}
						/>
						<stop
							offset='80%'
							style={{ stopColor: 'rgb(2,40,205)', stopOpacity: 0.8 }}
						/>
					</linearGradient>
				</defs>
				<rect height='30px' width='100%' fill='url(#grad1)'></rect>
				<text fill='#ffffff' font-size='20' font-family='Verdana' x='50' y='20'>
					{/* Today's winner is <Link style={{ textDecoration: 'underline', color: 'white' }} to ={`/app/profile/${winner[1].unique_name}`}> {winner[1].display_name}</Link> */}
					Todays winner is
					<Link
						style={{
							textDecoration: 'underline',
							color: 'white',
							'&:visited': {
								textDecoration: 'none',
								color: 'white',
							},
						}}
						to={`/app/profile/${'AdminOfficer99878'}`}
					>
						{/* {" Admin Officer"} */}
						{winner.map(function (d, idx) {
							return <span key={idx}>{idx === 0 && d[1].display_name}</span>
						})}
					</Link>
					<animate
						attributeName='x'
						from='-100'
						to='1080'
						dur='10s'
						repeatCount='indefinite'
					/>
				</text>
				Sorry, your browser does not support inline SVG.
			</svg>
		</div>
	)
}

export default FloatBar
