import React from 'react'
import { connect } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { menu, bottomMenu } from './menu'
import classnames from 'classnames'
import Search from './search'
import { Button } from '../index'

function BottomBar(props) {
	const location = useLocation()

	return (
		<div id='bottombar-nav' className='fixed-bottom border-top d-md-none'>
			<div className='conatiner-fluid h-100'>
				<div className='row h-100 align-self-center justify-content-center '>
					{bottomMenu.map((item, i) => {
						if (item.mobile)
							return (
								<span className='col-2 h-100 ' key={item.to}>
									<NavLink
										to={item.to}
										className={classnames(
											'd-flex flex-column justify-content-center align-items-center text-decoration-none h-100',
											{
												'text-dark fw-bold': location.pathname === item.to,
												'text-muted hover:text-gray-700': location.pathname !== item.to,
											}
										)}
									>
										<i className={' mt-2 font-24 flex-grow-1 bi bi-' + item.icon} />
										{/* <span className='font-14 mt-0'> {item.name} </span> */}
									</NavLink>
								</span>
							)
						else return <></>
					})}

					<span className='col-2 h-100 '>
						<NavLink
							to='/app/notification'
							className='d-flex justify-content-center align-items-center h-100'
						>
							{props.profile.unread_message_count === 0 ? (
								<></>
							) : (
								<div
									className='avatar-box avatar-16 h-100'
									style={{
										zIndex: 2,
										top: '-2px',
										right: '-35px',
										size: '0',
										height: '0px',
									}}
								>
									<span className='badge badge-primary bg-danger rounded-circle'>
										<span>{props.profile.unread_message_count}</span>
									</span>
								</div>
							)}
							<i className='bi bi-bell font-22 mx-2' />
						</NavLink>
						{/* <span className='font-14 mt-0'> {"Notifs"} </span> */}
					</span>
					<Button
						text={<i className='bi bi-search mx-2' />}
						variant='icon'
						color='dark'
						className='col-2 h-100 font-20 fw-bold mw-100'
						toggle='offcanvas'
						target='search_offcanvas'
					/>

					<span className='col-2 h-100 '>
						<NavLink
							to={'/app/househelp'}
							className={classnames(
								'd-flex flex-column justify-content-center align-items-center text-decoration-none h-100',
								{
									'text-dark fw-bold': location.pathname === '/app/househelp',
									'text-muted hover:text-gray-700':
										location.pathname !== '/app/househelp',
								}
							)}
						>
							{/* <i className={' mt-2 font-24 flex-grow-1 bi bi-' + "people"} /> */}
							{/* <span className='font-14 mt-0'> {item.name} </span> */}
							{/* <div className='avatar-box'>
									<img
										className='thumb-sm rounded-circle'
										src={househelp}
										alt='topbar-avatar'
									/>
								</div> */}
							<i class='bi bi-basket font-22'></i>
						</NavLink>
					</span>

					<div className=' dropdown d-flex col-2 align-items-center'>
						<a
							className={classnames(
								'd-flex flex-column justify-content-center align-items-center text-decoration-none h-100 dropdown-toggle waves-effect waves-light nav-user',
								{
									'text-dark fw-bold': location.pathname === 'app/profile/self',
									'text-muted hover:text-gray-700':
										location.pathname !== 'app/profile/self',
								}
							)}
							data-bs-toggle='dropdown'
							role='button'
							aria-haspopup='false'
							aria-expanded='false'
						>
							{props.profile && (
								<div className='avatar-box font-14'>
									<img
										className='thumb-sm rounded-circle'
										src={props.profile.picture}
										alt='topbar-avatar'
									/>
								</div>
							)}
						</a>
						<div
							className='dropdown-menu dropdown-menu-end'
							style={{ width: '100px' }}
						>
							<NavLink
								className='dropdown-item'
								// to={`/app/profile/${props.profile.unique_name}`}
								to={`/app/profile/self`}
							>
								<div className='fw-bold text-muted text-center text-truncate'>
									{props.profile && props.profile.display_name}
								</div>
							</NavLink>
							<div className='dropdown-divider' />
							<NavLink className='dropdown-item' to='/app/logout'>
								<i className='bi bi-power icon-xs icon-dual me-2' />
								Logout
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		token: state.general.token,
		brandname: state.general.brandname,
		profile: state.general.profile,
	}
}

export default connect(mapStateToProps, null)(BottomBar)
