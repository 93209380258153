import React from 'react'
import { connect } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { menu } from '..'
import Location from './location'
import Search from './search'

function Topbar(props) {
	const location = useLocation()
	React.useEffect(() => {}, [props.profile])

	return (
		<>
			<nav id='topbar-nav' className='navbar fixed-top navbar-light py-0 '>
				<div
					className='d-flex flex-grow-1 mx-auto px-2 px-md-4 align-items-center h-100'
					style={{ maxWidth: '1200px' }}
				>
					<Location
						location={props.location}
						updateLocation={props.updateLocation}
					/>
					<div className='d-flex flex-grow-1 h-100'>
						<div className='d-none d-md-flex align-self-center justify-content-center ms-4 h-100'>
							{menu.map((item, i) => (
								<NavLink
									key={item.to}
									to={item.to}
									className={classnames(
										'd-flex  mx-auto flex-column align-items-center me-3 text-decoration-none h-100',
										{
											'text-dark fw-bold': location.pathname === item.to,
											'text-muted hover:text-gray-700': location.pathname !== item.to,
										}
									)}
								>
									<i
										className={'flex-grow-1 font-22 bi bi-' + item.icon}
										style={{ color: '#0096FF' }}
									/>
									<span style={{ color: 'orange' }}> {item.name} </span>
								</NavLink>
							))}
						</div>
					</div>
					<div className='d-flex align-items-center h-100'>
						<div id='top' style={{ marginRight: '14rem', display: 'flex' }}>
							<img
								id='logo'
								src={'https://ic-storage.s3.us-east-2.amazonaws.com/assets/logo.png'}
								style={{ width: '59px', height: '59px' }}
							/>
							<h2 id='h' style={{ color: '#0096FF', fontWeight: 'bold' }}>
								INDIA
								<span style={{ color: 'orange', fontWeight: 'bold' }}>CONNECTS</span>
							</h2>
						</div>
						<Search />
						<NavLink
							to='/app/notification'
							style={{ color: '#0096FF' }}
							className='d-flex justify-content-center align-items-center h-100'
						>
							{props.profile.unread_message_count === 0 ? (
								<></>
							) : (
								<div
									className='avatar-box avatar-16'
									style={{
										zIndex: 2,
										top: '-24px',
										right: '-35px',
										size: '0',
										height: '0px',
									}}
								>
									<span className='badge badge-primary bg-danger rounded-circle'>
										<span>{props.profile.unread_message_count}</span>
									</span>
								</div>
							)}
							<i className='bi bi-bell mx-2' />
						</NavLink>

						<div className='dropdown d-flex align-items-center ms-2'>
							<a
								className='dropdown-toggle waves-effect waves-light nav-user'
								data-bs-toggle='dropdown'
								role='button'
								aria-haspopup='false'
								aria-expanded='false'
							>
								{props.profile && (
									<div className='avatar-box'>
										<img
											className='thumb-sm rounded-circle'
											src={props.profile.picture}
											alt='topbar-avatar'
										/>
									</div>
								)}
							</a>
							<div
								className='dropdown-menu dropdown-menu-end'
								style={{ width: '200px' }}
							>
								<NavLink
									className='dropdown-item'
									to={`/app/profile/${props.profile.unique_name}`}
								>
									<div className='fw-bold text-muted text-center text-truncate'>
										{props.profile && props.profile.display_name}
									</div>
								</NavLink>
								<div className='dropdown-divider' />
								<NavLink className='dropdown-item' to='/app/logout'>
									<i className='bi bi-power icon-xs icon-dual me-2' />
									Logout
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		profile: state.general.profile,
		token: state.general.token,
		location: state.general.location,
		brandname: state.general.brandname,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateLocation: (location) => {
			dispatch({ type: 'UPDATE_LOCATION', location: location })
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar)
